var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    staticClass: "v-data-table--line table-fixed v-data-table--mobile",
    attrs: {
      "dense": "",
      "hide-default-footer": "",
      "disable-sort": "",
      "disable-filtering": "",
      "disable-pagination": "",
      "no-data-text": "등록된 공지사항이 없습니다",
      "mobile-breakpoint": "768"
    },
    on: {
      "click:row": function (any, _ref) {
        var item = _ref.item;
        return _vm.showBoard(item);
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref2) {
          var value = _ref2.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.subject`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "text-truncate",
          attrs: {
            "data-idx": "5"
          }
        }, [_vm._v(_vm._s(item.subject))])];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false"
          }],
          staticClass: "btn-wrap"
        }, [_c('v-row', {
          staticClass: "row--xs"
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          attrs: {
            "outlined": "",
            "color": "grey darken-4"
          },
          on: {
            "click": function ($event) {
              return _vm.$emit('loadmore');
            }
          }
        }, [_vm._v("리스트")])], 1), _c('v-spacer'), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          attrs: {
            "color": "grey darken-4"
          },
          on: {
            "click": function ($event) {
              return _vm.$router.push('?mode=input');
            }
          }
        }, [_vm._v("글쓰기")])], 1)], 1)], 1), _c('div', {
          staticClass: "pagination-wrap"
        }, [_c('v-pagination', {
          attrs: {
            "value": _vm.page,
            "length": _vm.count,
            "total-visible": 11
          },
          on: {
            "change": _vm.changePage
          }
        })], 1)];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }